import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "card-header border-0 pt-5" }
const _hoisted_2 = { class: "card-title align-items-start flex-column" }
const _hoisted_3 = { class: "card-label fw-bolder fs-3 mb-1" }
const _hoisted_4 = { class: "text-muted fw-bold fs-7" }
const _hoisted_5 = {
  class: "card-toolbar",
  "data-kt-buttons": "true"
}
const _hoisted_6 = {
  class: "btn btn-sm btn-color-muted btn-active btn-active-primary active px-4 me-1",
  id: "kt_charts_widget_2_year_btn"
}
const _hoisted_7 = {
  class: "btn btn-sm btn-color-muted btn-active btn-active-primary px-4 me-1",
  id: "kt_charts_widget_2_month_btn"
}
const _hoisted_8 = {
  class: "btn btn-sm btn-color-muted btn-active btn-active-primary px-4",
  id: "kt_charts_widget_2_week_btn"
}
const _hoisted_9 = { class: "card-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_apexchart = _resolveComponent("apexchart")

  return (_openBlock(), _createBlock("div", {
    class: [_ctx.widgetClasses, "card"]
  }, [
    _createVNode("div", _hoisted_1, [
      _createVNode("h3", _hoisted_2, [
        _createVNode("span", _hoisted_3, _toDisplayString(_ctx.translate("memberStatus")), 1),
        _createVNode("span", _hoisted_4, _toDisplayString(_ctx.translate("moreThan500NewOrders")), 1)
      ]),
      _createVNode("div", _hoisted_5, [
        _createVNode("a", _hoisted_6, _toDisplayString(_ctx.translate("year")), 1),
        _createVNode("a", _hoisted_7, _toDisplayString(_ctx.translate("month")), 1),
        _createVNode("a", _hoisted_8, _toDisplayString(_ctx.translate("week")), 1)
      ])
    ]),
    _createVNode("div", _hoisted_9, [
      _createVNode(_component_apexchart, {
        type: "bar",
        options: _ctx.options,
        series: _ctx.series
      }, null, 8, ["options", "series"])
    ])
  ], 2))
}