
import { defineComponent } from "vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";

export default defineComponent({
  name: "widget-1",
  props: {
    widgetClasses: String
  },
  components: {},
  setup() {
    const labelColor = getCSSVariableValue("--bs-gray-500");
    const borderColor = getCSSVariableValue("--bs-gray-200");

    const options = {
      chart: {
        fontFamily: "inherit",
        type: "area",
        height: 350,
        toolbar: {
          show: false
        }
      },
      plotOptions: {},
      legend: {
        show: false
      },
      dataLabels: {
        enabled: false
      },
      fill: {
        type: "solid",
        opacity: 1
      },
      stroke: {
        curve: "smooth"
      },
      xaxis: {
        categories: ["Feb", "Mar", "Apr", "May", "Jun", "Jul"],
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        labels: {
          style: {
            colors: labelColor,
            fontSize: "12px"
          }
        },
        crosshairs: {
          position: "front",
          stroke: {
            color: labelColor,
            width: 1,
            dashArray: 3
          }
        },
        tooltip: {
          enabled: false
        }
      },
      yaxis: {
        labels: {
          style: {
            colors: labelColor,
            fontSize: "12px"
          }
        }
      },
      states: {
        normal: {
          filter: {
            type: "none",
            value: 0
          }
        },
        hover: {
          filter: {
            type: "none",
            value: 0
          }
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: "none",
            value: 0
          }
        }
      },
      tooltip: {
        style: {
          fontSize: "12px"
        },
        y: {
          formatter: function(val) {
            return "$" + val + " thousands";
          }
        }
      },
      colors: [
        "#E1BEE7",
        "#F50057",
        "#0D47A1",
        "#00897B",
        "#388E3C",
        "#EF6C00"
      ],
      grid: {
        borderColor: borderColor,
        strokeDashArray: 4,
        yaxis: {
          lines: {
            show: true
          }
        }
      },
      markers: {
        colors: [
          "#E1BEE7",
          "#F50057",
          "#0D47A1",
          "#00897B",
          "#388E3C",
          "#EF6C00"
        ],
        strokeColor: [
          "#fff",
          "#F50057",
          "#0D47A1",
          "#00897B",
          "#388E3C",
          "#EF6C00"
        ],
        strokeWidth: 3
      }
    };

    const series = [
      {
        name: "Net Profit",
        data: [60, 50, 80, 40, 100, 60]
      },
      {
        name: "Revenue",
        data: [70, 60, 110, 40, 50, 70]
      },
      {
        name: "Other value",
        data: [72, 30, 21, 42, 51, 75]
      },
      {
        name: "And other value",
        data: [27, 6, 111, 22, 3, 72]
      },
      {
        name: "other more",
        data: [9, 63, 0, 66, 33, 27]
      },
      {
        name: "plus",
        data: [18, 3, 60, 222, 330, 27]
      }
    ];

    return {
      options,
      series
    };
  }
});
