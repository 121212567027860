<template>
  <!--begin::Row-->
  <div class="row g-5 g-xl-12 mb-9">
    <div class="mb-3">
      <a
        :href="backUrl"
        @click="clearFilter"
        class="btn btn-sm btn-dark ms-auto"
      >
        <i class="bi bi-arrow-left" />
        {{ translate("back") }}
      </a>
    </div>

    <div class="col-xl-12">
      <div class="filter p-6 d-flex align-items-center">
        <!--begin::Datepicker-->
        <el-date-picker
          v-model="filter.fromDate"
          placeholder="From"
          name="fromDate"
        />
        <!--end::Datepicker-->

        <!--begin::Datepicker-->
        <el-date-picker
          v-model="filter.toDate"
          placeholder="To"
          name="toDate"
          class="ms-6"
        />
        <!--end::Datepicker-->

        <button
          v-if="filter.fromDate || filter.toDate"
          @click="clearFilter"
          class="btn btn-sm btn-dark btn-icon ms-auto"
        >
          <i class="bi bi-x" />
        </button>

        <button
          v-if="!filter.fromDate && !filter.toDate"
          @click="clearFilter"
          class="btn btn-sm btn-dark btn-icon ms-auto"
        >
          <i class="bi bi-arrow-right" />
        </button>
      </div>
    </div>
  </div>
  <!--end::Row-->

  <div v-if="filter.fromDate && filter.toDate" class="filter-results">
    <!--begin::Row-->
    <div class="row g-5 g-xl-8">
      <div class="col-xl-6">
        <ObjectiveProgress
          widget-classes="card-xl-stretch mb-xl-8"
        ></ObjectiveProgress>
      </div>
      <div class="col-xl-6">
        <MemberStatus widget-classes="card-xl-stretch mb-xl-8"></MemberStatus>
      </div>
    </div>
    <!--end::Row-->

    <!--begin::Row-->
    <div class="row g-5 g-xl-8">
      <div class="col-xl-4">
        <MemberCounter
          widget-classes="card-xl-stretch mb-xl-8"
          :height="150"
          color="success"
          title="Accepted"
          description="Marketplace Authors Chart"
          change="-260"
        ></MemberCounter>
      </div>

      <div class="col-xl-4">
        <MemberCounter
          widget-classes="card-xl-stretch mb-xl-8"
          :height="150"
          color="danger"
          title="Not accepted"
          description="Your Weekly Sales Chart"
          change="+100"
        ></MemberCounter>
      </div>

      <div class="col-xl-4">
        <MemberCounter
          widget-classes="card-xl-stretch mb-xl-8"
          :height="150"
          color="warning"
          title="Rejected"
          description="Marketplace Authors Chart"
          change="-260"
        ></MemberCounter>
      </div>
    </div>
    <!--end::Row-->

    <!--begin::Row-->
    <div class="row g-5 g-xl-8">
      <div class="col-xl-6">
        <ChartsWidget3 widget-classes="card-xl-stretch mb-xl-8"></ChartsWidget3>
      </div>
      <div class="col-xl-6">
        <ChartsWidget1 widget-classes="card-xl-stretch mb-xl-8"></ChartsWidget1>
      </div>
    </div>
    <!--end::Row-->

    <!--begin::Row-->
    <div class="row g-5 g-xl-8">
      <div class="col-xl-6">
        <ChartsWidget5 widget-classes="card-xl-stretch mb-xl-8"></ChartsWidget5>
      </div>
      <div class="col-xl-6">
        <ChartsWidget6 widget-classes="card-xl-stretch mb-xl-8"></ChartsWidget6>
      </div>
    </div>
    <!--end::Row-->

    <!--begin::Row-->
    <div class="row g-5 g-xl-8">
      <div class="col-xl-6">
        <ChartsWidget7 widget-classes="card-xl-stretch mb-xl-8"></ChartsWidget7>
      </div>
      <div class="col-xl-6">
        <ChartsWidget8 widget-classes="card-xl-stretch mb-xl-8"></ChartsWidget8>
      </div>
    </div>
    <!--end::Row-->
  </div>
</template>

<script>
import { defineComponent, onMounted } from "vue";
import ChartsWidget1 from "@/components/widgets/charts/Widget1.vue";
// import ChartsWidget2 from "@/components/widgets/charts/Widget2.vue";
import MemberStatus from "@/components/app/Reports/MemberStatus.vue";
import ChartsWidget3 from "@/components/widgets/charts/Widget3.vue";
// import ChartsWidget4 from "@/components/widgets/charts/Widget4.vue";
import ObjectiveProgress from "@/components/app/Reports/ObjectiveProgress.vue";
import ChartsWidget5 from "@/components/widgets/charts/Widget5.vue";
import ChartsWidget6 from "@/components/widgets/charts/Widget6.vue";
import ChartsWidget7 from "@/components/widgets/charts/Widget7.vue";
import ChartsWidget8 from "@/components/widgets/charts/Widget8.vue";

// import StatisticsWidget3 from "@/components/widgets/statsistics/Widget3.vue";
import MemberCounter from "@/components/app/Reports/MemberCounter.vue";

import { useI18n } from "vue-i18n";

import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";

export default defineComponent({
  name: "reports",
  components: {
    ChartsWidget1,
    // ChartsWidget2,
    MemberStatus,
    ChartsWidget3,
    // ChartsWidget4,
    ObjectiveProgress,
    ChartsWidget5,
    ChartsWidget6,
    ChartsWidget7,
    ChartsWidget8,
    // StatisticsWidget3,
    MemberCounter
  },
  data() {
    return {
      filter: {
        fromDate: new Date(),
        toDate: null
      }
    };
  },
  computed: {
    backUrl() {
      const organizationId = this.$route.params.id;
      return `/#/organization/${organizationId}`;
    }
  },
  methods: {
    clearFilter() {
      this.filter.fromDate = null;
      this.filter.toDate = null;
    }
  },
  setup() {
    const { t, te } = useI18n();
    const translate = text => {
      return te(text) ? t(text) : text;
    };
    onMounted(() => {
      setCurrentPageBreadcrumbs(translate("myReports"), ["Future Egg"]);

      MenuComponent.reinitialization();
    });

    return {
      translate
    };
  }
});
</script>

<style scoped>
.filter {
  background: #fff;
  border-radius: 7.5px;
  -moz-border-radius: 7.5px;
  -webkit-border-radius: 7.5px;
}
</style>
